import Swal from "sweetalert2";


export const swalCondicional = (optionsSwal, condicion = true) => {

    var promise = new Promise((resolve, reject) => {

        if (condicion)
        {
            Swal.fire(optionsSwal).then((continuar) => {
               
                resolve(continuar.value);
            });
        }
        else 
        {
            resolve(true);
        }

    });

    return promise;

}

export const swalAviso = (title, text, callback) => {


    Swal.fire({
        title: title,
        html: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
    }).then((confirmacion) => {

        if (confirmacion.value)
        {
            callback();
        }

    });


}

export const swalError = (title = 'Error', message = 'Se ha producido un error') => {

    Swal.fire({
        icon: "error",
        title: title,
        text: message,
    });

}

export const swalSuccess = (title = 'Éxito', message = 'Operación realizada con éxito') => {

    Swal.fire({
        icon: "success",
        title: title,
        text: message,
    });

}

export default Swal;