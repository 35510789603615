import { useQuery } from "@tanstack/react-query";
import { ejecutaAPI } from "app/helpers/libFunciones";
import { useDataArray } from "app/hooks/useDataArray";
import { TipoIVAResponse } from "./models/tiposIVA.interfaces";


export const getTiposIVA = async () => {


    const {success, respuesta, msg} = await ejecutaAPI('GET', `/tiposIVA`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg?.join(','));
    }
}

export const useTiposIVA = () => {

    const {data, isInitialLoading} = useQuery(['tipos_iva'], () => getTiposIVA());

    const listaTiposIVA = useDataArray<TipoIVAResponse[]>(data);

    return {
        data,
        isInitialLoading,
        listaTiposIVA

    }
}