import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { FormControl, FormHelperText, IconButton, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { BotonAnt, BotonAntClick, BotonSig, BotonSigClick } from "../../pages/utils";


export const customStyles =
    {
    headRow: {
        style: {
            border: 'none',
        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    }
  };




export const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };



const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    TextField: {

        width: '90%',
        borderRadius: '3px',
        marginLeft: '-10px',
        align: "left",

        "&: hover": {
            cursor: 'pointer'
        }
    },
    ClearButton: {
        position: 'absolute',
        right: '0px',
        top: '10px',
        cursor: 'pointer'
    },
    SearchButton: {
        position: 'absolute',
        right: '40px',
        top: '10px',
        cursor: 'pointer'
    } 
}));

const fontSizeIcons = { fontSize: 22 };


export const ClearButton = ({handleClear})=>(

    <IconButton onClick={handleClear} className={useStyles().ClearButton} aria-label="delete">
        <DeleteIcon style={fontSizeIcons} />
    </IconButton>
 
);

export const SearchButton = ({ handleSearch }) => {

    
    return (
    <IconButton onClick={handleSearch} className={useStyles().SearchButton} aria-label="delete">
        <SearchIcon style={fontSizeIcons} />
    </IconButton>
    )
}




//Filtros 

export const FilterComponent = ({ id, value, placeholder, onFilter,type="text",width="200px" }) => (       
    <TextField size="small" id={id} className={useStyles().TextField} type={type} style={{ minWidth: width ,maxWidth:width,marginRight:20}} variant="outlined" value={value} placeholder={placeholder} onChange={onFilter} />    
);



export const SelectComponent = ({ id,label,width="150px", value, options, onFilter,error,native=true}) => {   
   

    if(native===false){
        options=[{value:" ",nombre:"..."},...options];        
    }

    return(
    <FormControl className={useStyles.formControl} variant="outlined" >
        

        <TextField                         
            value={value} 
            select     
            variant="outlined"    
            onChange={(e) => { onFilter(e, id); }}
            label={label}
            size="small"
            style={{ minWidth: width }}
            placeholder={label}   
            inputProps={{
                name: id,
                id: id,
            }}
            
        >
              {                         
                (native===true)?                    
                        (options.map(n =>
                            <option key={n.value} value={`${n.value}`}>{n.nombre}</option>
                        ))                    
                    : (                        
                        options.map(n =>
                            <MenuItem key={n.value} value={n.value}>{n.nombre}</MenuItem>  
                        )
                    )                 
                         
             }
            
            
        </TextField>
        <FormHelperText>{error}</FormHelperText>
    </FormControl>
);    
}


// Hook
export function useLocalStorage(key, initialValue) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = value => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    return [storedValue, setValue];
}


export const options_SINO = [{ nombre: "", value: "" }, { nombre: "Si", value: "1" }, { nombre: "No", value: "0" }];


/* Botones de siguiente anterior, paginacion, debe estar en el state redux */
export const ToolbarPag = (props) => {

    const { data, id, url } = props;

    let prevId = 0
    let nextId = 0;


if(data && data.data){
       
    for(var i=0;i<data.data.length;i++){

        if(data.data[i].id===id){
            if(i>0)
              prevId=parseInt(data.data[i-1].id);
           
              if(i<data.data.length-1)   
             nextId=parseInt(data.data[i+1].id);
        }
    }

    
    return (
        <div>
            {(prevId > 0) ? (<BotonAnt name="Ant" link={`/${url}/${prevId}`} />) : <b></b>}
            {(nextId > 0) ? (<BotonSig name="Sig" link={`/${url}/${nextId}`} />) : <b></b>}
        </div>
    );
 }else{
     return null;
 }

}


/* Botones de siguiente anterior, paginacion, debe estar en el state redux */
export const ToolbarModal= (props) => {

    const { data, id, abrirModal } = props;

    let prevId = 0
    let nextId = 0;


    if (data.data) {

        for (var i = 0; i < data.data.length; i++) {

            if (data.data[i].id === id) {
                if (i > 0)
                    prevId = parseInt(data.data[i - 1].id);

                if (i < data.data.length - 1)
                    nextId = parseInt(data.data[i + 1].id);
            }
        }


        return (
            <div>
                {(prevId > 0) ? (<BotonAntClick name="Ant" func={()=>{abrirModal(prevId)}} />) : <b></b>}
                {(nextId > 0) ? (<BotonSigClick name="Sig" func={()=>{abrirModal(nextId)}} />) : <b></b>}
            </div>
        );
    } else {
        return null;
    }

}

