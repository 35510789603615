import Etiqueta from "app/componentes/generales/Etiqueta";
import { muestraPrecio } from "app/helpers/libFunciones";
import { useRoles } from "app/hooks/hooksPropios";
import { PreciosTrafico, ValoresEnvioCambioParcial } from "app/modules/trafico/models/trafico.types";
import EtiquetaPrecioEnvio from "./EtiquetaPrecioEnvio";

interface EtiquetasPreciosProps {
	id: string;
	precios: PreciosTrafico;
	tipoBeneficio: string | null;
	classes: any;
	handleCambioParcial: (datos: ValoresEnvioCambioParcial) => void;
	tieneValoraciones: boolean;
}

export default function EtiquetasPrecios({ id, precios, tipoBeneficio, classes, handleCambioParcial, tieneValoraciones }: EtiquetasPreciosProps) {
	const { esRolAdministrativo } = useRoles();

	let tooltipCoste = "Coste estimado";

	let margenUsar = precios.margen_est;

	let costeUsar = precios.coste_total;

	let colorUsarPrecio = "#43ac6a";

	if (tipoBeneficio == "2" && precios.coste_real > 0) {
		margenUsar = precios.margen_real;

		costeUsar = precios.coste_real;

		if (precios.margen_real && parseFloat(precios.margen_real) < 0) {
			colorUsarPrecio = "#ba3333";
		}
	}

	

	return (
		<>
			{esRolAdministrativo && precios.precio_total > 0 ? (
				<>
					<Etiqueta
						tooltip={tooltipCoste}
						texto={muestraPrecio(costeUsar) + " €"}
						clases={classes.etiqueta}
						estilos={{ backgroundColor: "#63c086", color: "white" }}
					/>
					<EtiquetaPrecioEnvio
						id={id}
						tooltip="Precio total sin IVA"
						precio={precios.precio_total.toString()}
						margen={margenUsar}
						costeReal={precios.coste_real}
						className={classes.etiqueta}
						handleCambioParcial={handleCambioParcial}
						sx={{ backgroundColor: `${colorUsarPrecio} !important`, color: "white !important" }}
						tieneValoraciones={tieneValoraciones}
					/>
				</>
			) : (
				<EtiquetaPrecioEnvio
					id={id}
					tooltip="Sin valorar"
					precio={precios.precio_total.toString()}
					costeReal={precios.coste_real}
					className={classes.etiqueta}
					handleCambioParcial={handleCambioParcial}
					sx={{ backgroundColor: "#f08a24 !important", color: "white !important" }}
					tieneValoraciones={tieneValoraciones}
				/>
			)}
		</>
	);
}
