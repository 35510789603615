import { useMutation, useQueryClient } from "@tanstack/react-query";
import { VerbosHTTP } from "app/models/types";
import { ejecutaAPI } from "../../../helpers/libFunciones";
import { UsuarioResponse } from "./useApiUsuario";

export interface GuardarUsuarioRequest {
    id: string | null;
    activo?: string;
    idRol?: number[];
    nombre: string;
    apellido1: string;
    apellido2: string;
    user: string;
    password: string | null;
    email: string;
    telefono: string;
    dni: string;
    idPais?: string | null;
    ciudad?: string | null;
    provincia?: string | null;
    calle?: string | null;
    numero?: string | null;
    cp?: string | null;
    fecha_cad_carnet_conducir?: string | null;
    observaciones?: string | null;
    idTarifaMensajero?: string[] | null;
}

type GuardarUsuarioResponse = any;

export const guardarUsuario = async (values: GuardarUsuarioRequest) => {


    let method: VerbosHTTP = "POST";

    let rutaId = '';

    if (values.id)
    {
        method = "PUT";

        rutaId = `/${values.id}`;
    }


    const { success, respuesta, msg } = await ejecutaAPI<GuardarUsuarioResponse>(method, `/users${rutaId}`, values);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(","));
    }

}

export const useApiGuardarUsuario = (id?: string) => {

    const queryClient = useQueryClient();

    return useMutation<UsuarioResponse, Error, GuardarUsuarioRequest, unknown>((values) => guardarUsuario(values),
    {
        onSuccess: () => {

            if (id)
            {   
                queryClient.invalidateQueries(['usuarios', id]);
            }

        }
    });
}