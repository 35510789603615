import { useQuery } from "@tanstack/react-query";
import { ejecutaAPI } from "../../../helpers/libFunciones";

export interface UsuarioResponse {
  id: string;
  user: string;
  nombre: string;
  apellido1: string;
  apellido2: string;
  email: string;
  telefono: string;
  calle: string;
  numero: string;
  cp: string;
  ciudad: string;
  provincia: string;
  idPais: string;
  dni: string;
  fecha_cad_carnet_conducir: string;
  observaciones: null;
  imagen: null;
  idCliente: string;
  activo: string;
  idUsuarioMultilogin: null;
  comisionComercial: string;
  comisionMensajero: string;
  roles: RolUsuario[];
  tarifasMensajero: string[];
  departamentosCliente: string[];
  cliente: ClienteUsuario;
  departamentos: DepartamentoUsuario[];
  clientesSubagencia: ClienteSubagencia[];
}

interface DepartamentoUsuario {
  id: string;
  idCliente: string;
  nombre: string;
  nombreDireccion: string;
  personaContacto: null | string;
  telefonoContacto: null;
  emailContacto: string;
  direccion: string;
  codigoPostal: string;
  localidad: string;
  provincia: string;
  idPais: string;
  activo: string;
  codigo: null | string;
  codigoCliente: null | string;
  idMensajeroDefecto: null;
  recogerDesdeDefecto: null | string;
  recogerHastaDefecto: null | string;
  observaciones: null;
  direcciones: any[];
}

interface ClienteUsuario {
  idMensajeroDefecto: string;
  recogerDesdeDefecto: string;
  recogerHastaDefecto: string;
}

interface RolUsuario {
  id: string;
  nombre: string;
  permisos: string[];
}

interface ClienteSubagencia {
  idUsuario: string;
  id: string;
  razonSocial: string;
}

export const getUsuario = async (idUsuario: string) => {


    const {success, respuesta, msg} = await ejecutaAPI<UsuarioResponse>('GET', `/users/${idUsuario}`);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(msg.join(", "));
    }
}

export const useApiUsuario = (idUsuario: string, optionsExtra = {}) => {

    const options = {
        enabled: Boolean(!isNaN(parseInt(idUsuario)) && parseInt(idUsuario) > 0),
        ...optionsExtra
    }

    const query = useQuery(['usuarios', idUsuario], () => getUsuario(idUsuario), options);

    return {
        ...query
    }
}