import { ejecutaAPI } from "app/helpers/libFunciones";

export const getDatosComisiones = async (values) => {

    const {success, respuesta, error} = await ejecutaAPI('POST', `/trafico/comisiones/datos`, values);

    if (success)
    {
        return respuesta;
    }
    else 
    {
        throw new Error(error);
    }

}