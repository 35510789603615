import { ModificacionParcialEnvioRequest } from "app/hooks/api/envios/useApiModificacionParcialEnvio";
import { DireccionTrafico, ValoresEnvioCambioParcial } from "../models/trafico.types";

export default function transformaDireccionTrafico(direccionTrafico: any): DireccionTrafico {
	return {
		nombre: direccionTrafico.nombre,
		direccion: direccionTrafico.direccion,
		cp: direccionTrafico.cp,
		localidad: direccionTrafico.localidad,
		provincia: direccionTrafico.provincia,
	};
}



export const EnvioParcialMapper = {
	toRequest: (values: ValoresEnvioCambioParcial) => {

        let devolver: ModificacionParcialEnvioRequest = {
            id: values.id
        }

        if (values.peso)
        {
            devolver.peso = values.peso;
        }

		if (values.precio)
		{
			devolver.precio = values.precio;
		}

		return devolver;
	},
};

export function transformaDatosParcialesEnvioParaTrafico(datos: ValoresEnvioCambioParcial, filaTrafico: any) {

	let devolver: any = {};

	if (datos.peso)
	{
		devolver.peso = datos.peso;
	}

	if (datos.precio)
	{
		if (filaTrafico.valoraciones?.length > 0)
		{
			let nuevasValoraciones = [
				{
					...filaTrafico.valoraciones[0],
					precio: datos.precio
				}
			];

			devolver.valoraciones = nuevasValoraciones;
		}
		else 
		{
			//Nos inventamos una valoración temporal
			let valoracion = {
				coste: filaTrafico.importeCostesReales,
				precio: datos.precio,
				id: "-1",
				idEnvio: datos.id,
				referencia: "Paquete",
				servicio: "",
				info: "",
				infoSinCoste: "",
				idRef: "",
				idVariable: null,
				idServicio: null,
				ruta: "",
				adicional: "0",
				tipoIVA: null
			}

			devolver.valoraciones = [valoracion];

		}

	}



	return devolver;
}