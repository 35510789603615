import { redes } from "app/helpers/constants";
import { useRoles } from "app/hooks/hooksPropios";
import { damePreciosParaTrafico } from "app/modules/trafico/helpers/trafico.helper";
import moment from "moment";

export const useDatosTrafico = ({ estados, datos, seleccionados }) => {
	const { esRolAdministrativo, esCliente } = useRoles();

	var estadoTarjeta = estados.filter((estado) => datos.estado.idEstado === estado.id);

	var codigoEstado = "";

	var datosTratados = datos;

	var tipo = datosTratados.tipo.substring(0, 1).toUpperCase();

	if (datosTratados.tipo == "recogidaFueraPlaza") {
		tipo = "RP";
	}

	var datosIncidencia = {
		incidentado: false,
		textoIncidencia: "",
	};

	var estado = {
		estado: "",
		usuario: "",
		fecha: "",
		observaciones: "",
	};

	const desactivarMensajero = tipo === "E" && datos.distribucionPropia !== "1";

	if (estadoTarjeta.length > 0) {
		codigoEstado = estadoTarjeta[0].codigo;
	}

	if (!datosTratados.horaDesde) {
		datosTratados.horaDesde = "09:00:00";
	}

	if (!datosTratados.horaHasta) {
		datosTratados.horaHasta = "19:00:00";
	}

	if (datosTratados.horaHasta === "00:00:00" || datosTratados.horaHasta === "00:00") {
		datosTratados.horaHasta = "23:59:00";
	}

	datosTratados.horaDesde = moment(datosTratados.horaDesde, "HH:mm").format("HH:mm");

	datosTratados.horaHasta = moment(datosTratados.horaHasta, "HH:mm").format("HH:mm");

	if ((tipo === "E" && codigoEstado === "incidencia") || (tipo === "R" && datosTratados.idTipoIncidencia)) {
		datosIncidencia.incidentado = true;

		datosIncidencia.textoIncidencia = tipo === "E" ? datosTratados.estado.observaciones : datosTratados.textoIncidencia;
	}

	var mostrarFinalizarRecogida = false;

	if (tipo === "R" && codigoEstado !== "terminado") {
		mostrarFinalizarRecogida = true;
	}

	var elementoSeleccionado = false;

	if (seleccionados && seleccionados.indexOf(datos.id) !== -1) {
		elementoSeleccionado = true;
	}

	//Envio
	if (tipo === "E") {
		if (datos.bultosEstadosRed && datos.bultosEstadosRed.length > 0 && datos.bultosEstadosRed[0].seguimiento?.nombre) {
			estado.estado = datos.bultosEstadosRed[0].seguimiento.nombre;

			estado.fecha = datos.bultosEstadosRed[0].seguimiento.fecha;

			estado.usuario = datos.bultosEstadosRed[0].seguimiento.usuario;

			estado.observaciones = datos.bultosEstadosRed[0].seguimiento.observaciones;
		}
	} else {
		//Recogida
		if (datosTratados.estado?.estado) {
			estado.estado = datosTratados.estado.estado;

			estado.usuario = datosTratados.estado.usuario;

			estado.fecha = datosTratados.estado.fecha;
		}
	}

	let estadoTooltip = estado.estado;

	if (estado.estado && (esRolAdministrativo || esCliente)) {
		estadoTooltip += ` (${estado.usuario} ${estado.fecha})`;
	}

	const codExpedicion =
		datos.idRedMensaglobalDatos == redes.TIPSA ? `${datos.codAgencia}${datos.codAgencia}${datos.codExpedicion}` : datos.codExpedicion;


	const precios = damePreciosParaTrafico(datos.valoraciones, datos.importeCostesReales);
	
	const tieneValoraciones = datos.valoraciones && datos.valoraciones.length > 0;

	return {
		datosIncidencia,
		codigoEstado,
		datosTratados,
		desactivarMensajero,
		mostrarFinalizarRecogida,
		elementoSeleccionado,
		estado,
		tipo,
		estadoTooltip,
		codExpedicion,
		precios,
		tieneValoraciones
	};
};
