import Yup, { emptyStringToNull } from '../../../librerias/yup';
import { objetoSchemaCamposAdicionales } from './config/ConfigCampos';

const objetoDireccion = Yup.object({
    id: Yup.string().nullable().default(""),
    nombre: Yup.string().when('$existeEnvio', {
        is: true,
        then: Yup.string().nullable().default(""),
        otherwise: Yup.string().required('El nombre de dirección es requerido').nullable().default("")
    }),
    personaContacto: Yup.string().nullable().default(""),
    telefonoContacto: Yup.string().nullable().default(""),
    emailContacto: Yup.string().email('El email no es válido').nullable().default(""),
    direccion: Yup.string().when('$existeEnvio', {
        is: true,
        then: Yup.string().nullable().default(""),
        otherwise: Yup.string().required('La dirección es requerida').nullable().default("")
    }),
    cp: Yup.string().required('El cp es requerido').nullable().default(""),
    provincia: Yup.string().nullable().default(""),
    localidad: Yup.string().when('$existeEnvio', {
        is: true,
        then: Yup.string().nullable().default(""),
        otherwise: Yup.string().required('La localidad es requerida').nullable().default("")
    }),
    idPais: Yup.string().required('El país es requerido').nullable().default(""),
    dni: Yup.string().when('$existeEnvio', {
        is: true,
        then: Yup.string().nullable().default(""),
        otherwise: Yup.string().dni('El dni no es válido').nullable().default("")
    }),
    habitual: Yup.boolean().nullable().default(false),
    observaciones: Yup.string().nullable().default("")
});


var objetoSchemaEnvio={
    id: Yup.string().nullable().default(""),
    idRecogida: Yup.string().when('$valoresActuales', {
        is: (valoresActuales) => (valoresActuales.recogidaFueraPlaza),
        then: Yup.string().nullable().default(""),
        otherwise: Yup.string().required('Requerido').default("")
    }),
    idServicio: Yup.string().required('El servicio es requerido').default(""),
    idRuta: Yup.string().nullable().default(""),
    portes: Yup.string().required('Los portes son requeridos').default(""),
    idServicioTipo: Yup.string().required('El tipo de servicio es requerido').default(""),
    bultos: Yup.number().positive('Los bultos deben ser mayor que cero').nullable().default(1).required('Requerido'),
    peso: Yup.number().positive('El peso debe ser mayor que cero').nullable().default(1).required('Requerido'),
    kmAdicionales: Yup.number().transform(emptyStringToNull).nullable().default(null),
    valorDeclarado: Yup.number().transform(emptyStringToNull).nullable().default(null),
    descDeclarado: Yup.string().nullable().default(""),
    contraReembolso: Yup.number().transform(emptyStringToNull).nullable().default(null),
    observaciones: Yup.string().nullable().default(""),
    referencia: Yup.string().nullable().default(""),
    importeCostesReales: Yup.number().transform(emptyStringToNull).nullable().default(null),
    idClienteTransmitible: Yup.string().nullable().default(""),
    idDepartamentoTransmitible: Yup.string().nullable().default(""),
    importeDebidos: Yup.number().transform(emptyStringToNull).nullable().default(null),
    pesoTransmitible: Yup.number().transform(emptyStringToNull).positive('El peso transmitible debe ser mayor que cero').nullable().default(null),
    margenReduccion: Yup.number().transform(emptyStringToNull).positive('El porcentaje de reducción debe ser mayor que cero').nullable().default(null),
    debidos: Yup.number().transform(emptyStringToNull).positive('Los portes debidos deben ser mayor que cero').nullable().default(null),
    idDelegacion: Yup.string().required("La delegación es obligatoria").default(""),
    oficinaRed: Yup.object().default({}).nullable(),
    direccion: objetoDireccion,
    direccionRecogida: objetoDireccion,
    valoracionManual: Yup.boolean().nullable().default(false),
    pagadoReembRed: Yup.boolean().nullable().default(false),
    pagadoReembCliente: Yup.boolean().nullable().default(false),
    observPagadoCliente: Yup.string().nullable().default(""),
    observPagadoRed: Yup.string().nullable().default(""),  
    fechaReembPagadoRed: Yup.string().nullable(),
    fechaReembPagadoCliente: Yup.string().nullable(),
    recogidaFueraPlaza: Yup.string().nullable().default(false),
    idDepartamentoRecogida: Yup.string().nullable(),
    fechaRecogida: Yup.string().when('$valoresActuales', {
        is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === "1" || valoresActuales.recogidaFueraPlaza === true) && !(valoresActuales.id>0)),
        then: Yup.string().required('Debes seleccionar la fecha de recogida').nullable().default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    observacionesRecogida: Yup.string().nullable().default(""),
    horaRecogidaDesde: Yup.string().when('$valoresActuales', {
        is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === "1" || valoresActuales.recogidaFueraPlaza === true) && !(valoresActuales.id > 0)),
        then: Yup.string().required('Debes seleccionar la hora desde').nullable().default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    horaRecogidaHasta: Yup.string().when('$valoresActuales', {
        is: (valoresActuales) => ((valoresActuales.recogidaFueraPlaza === "1" || valoresActuales.recogidaFueraPlaza === true) && !(valoresActuales.id > 0)),
        then: Yup.string().required('Debes seleccionar la hora desde').default(""),
        otherwise: Yup.string().nullable().default("")
    }),
    dividirExp: Yup.boolean().nullable().default(false),
    comisionComercial: Yup.number().transform(emptyStringToNull).nullable(),
    comisionMensajero: Yup.number().transform(emptyStringToNull).nullable(),
    comisionManual: Yup.string().nullable().default("0"),
    idComercial: Yup.string().nullable(),
    fecha: Yup.string().when('$valoresActuales', {
        is: (valoresActuales) => valoresActuales.id && valoresActuales.id > 0,
        then: Yup.string().required('Debes seleccionar la fecha'),
        otherwise: Yup.string().nullable().default("")
    }),
    idMensajero: Yup.string().nullable(),
    horaDesde: Yup.string().nullable(),
    horaHasta: Yup.string().nullable(),
    distribucionPropia: Yup.boolean().nullable(),
    refCodigoBarras: Yup.string().nullable()

};


export const schemaEnvios = Yup.object(Object.assign(objetoSchemaEnvio, objetoSchemaCamposAdicionales));
  


export const schemaDistribucion = Yup.object({
    id: Yup.string().required('Requerido'),
    fecha: Yup.string().required('Requerido'),
    idMensajero: Yup.string().nullable(),
    horaDesde: Yup.string().nullable(),
    horaHasta: Yup.string().nullable(),
    distribucionPropia: Yup.boolean().nullable()
});

export const schemaImportacion = Yup.object({
    identificador: Yup.string().default(""),
    idRed: Yup.string().default(""),
    idRecogida: Yup.string().default(""),
    idCliente: Yup.string().default(""), //Si está la recogida, no me hará falta este campo, y a la inversa.
    idReferencia: Yup.string().when('$existeEnvio', {
        is: true,
        then: Yup.string().nullable().default(""),
        otherwise: Yup.string().required("No se ha podido obtener una referencia del servicio seleccionado").default("")
    }),
    idDepartamento: Yup.string().default(""),
    idServicio: Yup.string().required('El servicio es obligatorio').default(""),
    codExpedicion: Yup.string().nullable().default(""),
    fecha: Yup.string().nullable().default(""),
    idRuta: Yup.string().nullable().default(""),
    portes: Yup.string().required('Los portes son obligatorios').default(""),
    importeDebidos: Yup.number().transform(emptyStringToNull).nullable().default(null),
    idServicioTipo: Yup.string().required('El tipo de servicio es obligatorio').default(""),
    bultos: Yup.number().transform(emptyStringToNull).min(0, 'Los bultos no pueden ser negativos').nullable().default(1),
    peso_original: Yup.number().transform(emptyStringToNull).nullable(),
    peso: Yup.number().transform(emptyStringToNull).min(0,'El peso no puede ser negativo').nullable().default(1),
    pesoVolumetrico: Yup.number().transform(emptyStringToNull).nullable().default(null),
    ancho: Yup.number().transform(emptyStringToNull).min(0, 'El ancho no pueden ser negativo').nullable().default(null),
    alto: Yup.number().transform(emptyStringToNull).min(0, 'El alto no pueden ser negativo').nullable().default(null),
    largo: Yup.number().transform(emptyStringToNull).min(0, 'El largo no pueden ser negativo').nullable().default(null),
    kmAdicionales: Yup.number().transform(emptyStringToNull).min(0).nullable().default(null),
    valorDeclarado: Yup.number().transform(emptyStringToNull).min(0).nullable().default(null),
    contraReembolso: Yup.number().transform(emptyStringToNull).min(0).nullable().default(null),    
    referencia: Yup.string().nullable().default(""),
    idDelegacion: Yup.string().required("La delegación es obligatoria").default(""),
    direccion: objetoDireccion,
    direccionRecogida: objetoDireccion,
    importeCostesReales: Yup.number().transform(emptyStringToNull).nullable().default(null),        
});
