import { Box, BoxProps, SxProps } from "@mui/material";
import { FormTextInput } from "app/componentes/formularios/FormTextInput";
import { Form } from "app/componentes/formularios/form";
import BotonCircular from "app/componentes/generales/BotonCircular";
import TienePermisos from "app/helpers/TienePermisos";
import { muestraPrecio } from "app/helpers/libFunciones";
import { envios } from "app/helpers/permisos";
import { useRoles } from "app/hooks/hooksPropios";
import { swalError } from "app/librerias/swal";
import {
	InitialFormCambiarPrecioValues,
	ValidatedFormCambiarPrecioValues,
	ValoresEnvioCambioParcial,
} from "app/modules/trafico/models/trafico.types";
import { TooltipG } from "app/pages/utils";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MdEdit, MdOutlineSave } from "react-icons/md";

interface EtiquetaPrecioEnvioProps extends BoxProps {
	id: string;
	precio: string;
	margen?: string | null;
	tooltip: string;
	className?: string;
	costeReal?: string | null;
	handleCambioParcial: (datos: ValoresEnvioCambioParcial) => void;
	tieneValoraciones: boolean;
}

export default function EtiquetaPrecioEnvio(props: EtiquetaPrecioEnvioProps) {

	const {
		id,
		precio,
		margen,
		className,
		handleCambioParcial,
		tooltip,
		sx,
		costeReal,
		tieneValoraciones,
		...other
	} = props;

	const {esRolAdministrativo, esMensajero, esCliente} = useRoles();

	const [modoEditar, setModoEditar] = useState(false);

	const puedeEditar = (esCliente || esRolAdministrativo || esMensajero) && TienePermisos([], [envios.editar, envios.crear]);

	const styleInput = {
		padding: "0px 5px",
		fontSize: "12px",
		width: "60px",
		color: "white",
	};

	const form = useForm<InitialFormCambiarPrecioValues>({
		defaultValues: {
			id,
			precio: parseFloat(precio).toFixed(2),
		},
	});

	const onSubmit = (values: ValidatedFormCambiarPrecioValues) => {
		setModoEditar(false);

		if (values.precio !== precio) {
			if (parseFloat(values.precio) > 0) {
				handleCambioParcial(values);
			} else {
				swalError("Error", "El precio debe ser mayor que 0");
			}
		}
	};

	const activarEdicion = () => {

		if (puedeEditar)
		{
			if (tieneValoraciones || costeReal !== null)
			{
				setModoEditar(true);
			}
			else 
			{
				swalError("Error", "No se puede editar el precio de un envío sin valoraciones ni costes");
			}
		}
	}

	let sxBox: SxProps = {
		cursor: "pointer",
		display: "flex !important",
	};

	if (modoEditar && sxBox) {
		sxBox.padding = "0px";
	}

	return (

		<Box
			className={className}
			alignItems="center"
			gap="7px"
			px="5px"
			sx={{ ...sxBox, ...sx }}
			onClick={() => activarEdicion()}
			{...other}
		>
			{!modoEditar ? (
				<TooltipG title={tooltip}>
					<>
						<span>
							{muestraPrecio(precio)} € {margen ? `(${muestraPrecio(margen)}%)` : ""}
						</span>
						{puedeEditar && <MdEdit />}
					</>
				</TooltipG>
			) : (
				<Form {...form} onSubmit={onSubmit}>
					<FormTextInput hooks type="hidden" name="id" />
					<Box display="flex" alignItems="center" gap="5px">
						<FormTextInput
							inputProps={{ style: styleInput, step: ".01" }}
							hooks
							type="number"
							name="precio"
							clase="sinEstilo"
							margin="none"
						/>
						<BotonCircular type="submit" variant="contained" color="primary">
							<MdOutlineSave />
						</BotonCircular>
					</Box>
				</Form>
			)}
		</Box>
	);
}
