import { GuardarUsuarioRequest } from "app/hooks/api/usuarios/useApiGuardarUsuario";
import { UsuarioResponse } from "app/hooks/api/usuarios/useApiUsuario";
import { cloneDeep, omit } from "lodash";
import { InitialFormUsuarioClienteValues, InitialFormUsuarioValues, ValidatedFormUsuarioClienteValues, ValidatedFormUsuarioValues } from "../models/usuarios.types";

export const UsuarioMapper = {
	toForm: (usuario?: UsuarioResponse): InitialFormUsuarioValues => {
		let initialValues: InitialFormUsuarioValues = {
			id: null,
			idCliente: null,
			idDepartamento: [],
			activo: "1",
			idRol: [],
			nombre: "",
			password: null,
			repetir_pass: null,
			apellido1: "",
			apellido2: "",
			user: "",
			email: "",
			telefono: null,
			dni: null,
			idPais: null,
			ciudad: null,
			provincia: null,
			calle: null,
			numero: null,
			cp: null,
			fecha_cad_carnet_conducir: null,
			observaciones: null,
			comisionComercial: null,
			comisionMensajero: null,
			idTarifaMensajero: [],
			idClienteSubagencia: []
		};

		if (usuario)
		{

			initialValues = {
				id: usuario.id,
				idCliente: usuario.idCliente ?? null,
				activo: usuario.activo,
				idRol: usuario.roles?.map((rol) => parseInt(rol.id)) ?? [],
				nombre: usuario.nombre,
				apellido1: usuario.apellido1,
				apellido2: usuario.apellido2,
				user: usuario.user,
				password: null,
				repetir_pass: null,
				email: usuario.email,
				telefono: usuario.telefono,
				dni: usuario.dni,
				idPais: usuario.idPais,
				ciudad: usuario.ciudad,
				comisionComercial: usuario.comisionComercial,
				comisionMensajero: usuario.comisionMensajero,
				provincia: usuario.provincia,
				calle: usuario.calle,
				numero: usuario.numero,
				cp: usuario.cp,
				fecha_cad_carnet_conducir: usuario.fecha_cad_carnet_conducir,
				observaciones: usuario.observaciones,
				idDepartamento: usuario.departamentosCliente,
				idTarifaMensajero: usuario.tarifasMensajero,
				idClienteSubagencia: usuario.clientesSubagencia.map(cliente => cliente.id) ?? []

			}

		}


		return initialValues;
	},
	toRequest: (values: ValidatedFormUsuarioValues): GuardarUsuarioRequest => {

		let valoresEnviar = cloneDeep(omit(values, ['repetir_pass']));

		return valoresEnviar;

	}
};

export const UsuarioClienteMapper = {
	toForm: (idCliente: string, usuario?: UsuarioResponse): InitialFormUsuarioClienteValues => {
		let initialValues: InitialFormUsuarioClienteValues = {
			id: null,
			idCliente: idCliente,
			idDepartamento: [],
			activo: "1",
			nombre: "",
			password: null,
			apellido1: "",
			apellido2: "",
			user: "",
			email: "",
			telefono: null,
			dni: null
		};

		if (usuario)
		{

			initialValues = {
				id: usuario.id,
				idCliente: usuario.idCliente ?? null,
				activo: usuario.activo,
				nombre: usuario.nombre,
				apellido1: usuario.apellido1,
				apellido2: usuario.apellido2,
				user: usuario.user,
				password: null,
				email: usuario.email,
				telefono: usuario.telefono,
				dni: usuario.dni,
				idDepartamento: usuario.departamentosCliente

			}

		}


		return initialValues;
	},
	toRequest: (values: ValidatedFormUsuarioClienteValues): GuardarUsuarioRequest => {

		let valoresEnviar = cloneDeep(values);

		return valoresEnviar;

	}
};
