import { swalError } from 'app/librerias/swal';
import { isEmpty, last } from 'lodash';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { copiaObjeto, ejecutaAPI, isCanvasEmpty } from '../helpers/libFunciones';
import { schemaEnvioMensajero } from '../pages/home/mensajero/envios/schemas';
import { useGlobalLoader } from './hooksPropios';



export const FichaEntregaMensajeroContext = React.createContext();

const entregaReducer = (state, action) => {

    switch (action.type) {
        case 'CARGANDO_INIT': 
            return {...state, cargando: true}
        case 'SET_ENVIO':

            var estadoActual = '';

            if (!isEmpty(action.payload.estados))
            {
                var ultimoEstado = last(action.payload.estados);

                if (!isEmpty(ultimoEstado))
                {
                    estadoActual = ultimoEstado.codigo;
                }
            }

            return {...state, cargando: false, datosEnvio: action.payload, estadoEntrega: estadoActual, imagenes: action.payload.imagenes}
        case 'SET_ERROR': 
            return {...state, cargando: false, errores: {...state.errores, error: true, msg: action.payload}}
        case 'SET_FICHEROS_ENTREGA':
            return {...state, imagenes: action.payload}
        default:
            throw new Error();
      }
}


export const FichaEntregaMensajeroProvider = ({children}) => {

    const [entrega, dispatch] = useReducer(entregaReducer, {

        datosEnvio: {},
        estadoEntrega: '',
        cargando: true,
        imagenes: [],
        errores: {error: false, msg: ""}
        
    });

    const refFirma = useRef();

    return (
        <FichaEntregaMensajeroContext.Provider value={[entrega, dispatch, refFirma]}>
            {children}
        </FichaEntregaMensajeroContext.Provider>
    )
}

export const useFichaEntregaMensajero = (id) => {

    const [entrega, dispatch] = useContext(FichaEntregaMensajeroContext);



    useEffect(() => {

        (async () => {


            dispatch({type: 'CARGANDO_INIT'});

            const datosEnvioAPI = await ejecutaAPI('GET', `/envios/${id}`);

            if (datosEnvioAPI.success)
            {

                dispatch({type: 'SET_ENVIO', payload: datosEnvioAPI.respuesta});
            }
            else 
            {
                dispatch({type: 'SET_ERROR', payload: 'Se ha producido un error obteniendo los datos del envío'});
            }

            
            

        })(); 



    }, [id, dispatch]);

    return {
        ...entrega,
        dispatch,

    }

}

export const useFichaEntregaMensajeroContext = () => {

    var [entrega, dispatch, refFirma] = useContext(FichaEntregaMensajeroContext);

    const {setGlobalCargando} = useGlobalLoader();

    const history = useHistory();


    const subirFirma = async (canvas) => {


        return new Promise((resolve, reject) => {

            let isEmpty = isCanvasEmpty(canvas);

            if (isEmpty)
            {
                resolve(null);
            }
            else 
            {
                canvas.toBlob(async (blob) => {

                    const datosForm = new FormData();

                    datosForm.append('fichero', blob, 'firma.png')

                    var resultado = await ejecutaAPI('POST', '/ficheros', datosForm);

                    if (resultado.success)
                    {
                        resolve(resultado.respuesta.id);
                    }
                    else 
                    {
                        reject(new Error('Se ha producido un error subiendo la firma'));
                    }

                });
            }
        });
    }

    const handleSubmit = async (values) => {

        let canvas = refFirma.current.getCanvas();
        
        setGlobalCargando(true);

        try 
        {

            const idFicheroFirma = await subirFirma(canvas);
            
            var datosEnviar = copiaObjeto(values);

            datosEnviar.idFicheroFirma = idFicheroFirma;

            datosEnviar.imagenes = entrega.imagenes.map(imagen => imagen.id);

            var resultadoAPIEntrega = await ejecutaAPI('PUT', `/envios/${entrega.datosEnvio.id}`, datosEnviar);
            
            if (resultadoAPIEntrega.success)
            {

                var datosEstado = {
                    estado: "entregado"
                }

                var resultadoEstado = await ejecutaAPI('POST', `/envios/${entrega.datosEnvio.id}/estados`, datosEstado);

                if (resultadoEstado.success)
                {

                    resultadoEstado.respuesta.codigo = datosEstado.estado;

                    resultadoAPIEntrega.respuesta.estados.push(resultadoEstado.respuesta);

                    //dispatch({type: 'SET_ENVIO', payload: resultadoAPIEntrega.respuesta});

                    history.push("/mensajero/trafico");

                    
                }
                else 
                {
                    Swal.fire({
                        title: 'Error',
                        text: 'Se ha producido un error',
                        icon: 'error'
                    });
                }

            }
            else 
            {
                Swal.fire({
                    title: 'Error',
                    text: 'No se ha podido guardar los datos de la entrega',
                    icon: 'error'
                });
            }
        }
        catch(error)
        {
            swalError('Error', error.message);
        }

        setGlobalCargando(false);

    }

    const handleSetFicheros = (ficheros) => {

        dispatch({type: 'SET_FICHEROS_ENTREGA', payload: ficheros});

    }

    const handleSubmitIncidencia = async (values) => {
    
        setGlobalCargando(true);

        var datosEnviar = copiaObjeto(values);

        var imagenes = datosEnviar.imagenes;

        datosEnviar.imagenes = imagenes.map(imagen => imagen.id);

        var resultadoEstado = await ejecutaAPI('POST', `/envios/${entrega.datosEnvio.id}/estados`, datosEnviar);

        if (resultadoEstado.success)
        {

            var nuevoEntrega = copiaObjeto(entrega.datosEnvio);

            nuevoEntrega.estados.push(resultadoEstado.respuesta);

            //dispatch({type: 'CARGANDO_INIT'});

            //const datosEnvioAPI = await ejecutaAPI('GET', `/envios/${entrega.datosEnvio.id}`);

            /*if (datosEnvioAPI.success)
            {*/

                //dispatch({type: 'SET_ENVIO', payload: datosEnvioAPI.respuesta});

            history.push("/mensajero/trafico");
            
            /*}
            else 
            {
                dispatch({type: 'SET_ERROR', payload: 'Se ha producido un error obteniendo los datos del envío'});
            }*/

         

        }
        else 
        {
            Swal.fire({
                title: 'Error',
                text: 'Se ha producido un error guardado el estado',
                icon: 'error'
            });
        }

        setGlobalCargando(false);
    }

    return {
        ...entrega,
        schemaEnvioMensajero,
        dispatch,
        handleSubmit,
        handleSetFicheros,
        handleSubmitIncidencia,
        refFirma
    }
}